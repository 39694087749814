'use strict';

var base = require('base/login/login');
var util = require('../util/util');
var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');

module.exports = $.extend({}, base, {
    initializePage: function () {
        if (window.location.hash.indexOf('register-section') >= 0 || window.location.search.indexOf('register=1') >= 0) {
            $('.nav-tabs a[href="#register-section"]').tab('show');
        }
        var productLikes = localStorage.getItem('ProductLikes');
        if (productLikes) {
            $('.js-likes-login').val(productLikes);
        }
    },
    loginRegisterActions: function () {
        $('body')
            .on('click', '.login-page .nav-link', function () {
                util.scrollBrowser(0);
            })
            .on('click', '.create-account, input:checkbox[id^="registration-form-"]', function () {
                var $checkboxes = $(".registration input:checkbox[id^='registration-form-']");

                $checkboxes.prop('required', true).addClass('is-invalid');

                if ($checkboxes.is(':checked')) {
                    $checkboxes.prop('required', false).removeClass('is-invalid');
                }
            });
    },
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $('body').spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $('body').trigger('event:LoginFail', data);
                        $('form.login').trigger('login:error', data);
                        window.location.reload();
                    } else {
                        $('form.login').trigger('login:success', data);
                        localStorage.removeItem('ProductLikes');
                        $('body').trigger('event:LoginSuccess', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        window.location.reload();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var savedLikes = localStorage.getItem('ProductLikes');
            var url = form.attr('action');

            if (savedLikes) {
                url = url + '&' + $.param(JSON.parse(savedLikes));
            }

            $('body').spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        $('body').trigger('event:RegisterFail', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        localStorage.removeItem('ProductLikes');
                        $('body').trigger('event:RegisterSuccess', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $('body').spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        // force focus of email field
                        $('#reset-password-email').focus();
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.subtitle, .send-email-btn, .bottom-message').hide();
                        $('.login-page').addClass('email-sent');
                        $('.request-password-body').empty().append(data.receivedMsgBody);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    }
});
